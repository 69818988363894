ng2-smart-table{
    td + td,
    th + th
    {
        border-left: 1px solid $table-border-color;
    }
    table tr th {
        font-weight: 100 !important;
    }
    table tr:last-of-type th {
        padding-bottom: 1em !important;
    }
    td {
        border-top: solid 1px $table-border-color;
    }

    .ng2-smart-sort-link.sort, .ng2-smart-sort {
        color: white;
    }
    a.sort.asc::after, a.sort.desc::after {
        // this concerns the color of the  sorting 'pyramid' icon
        border-bottom-color: white !important;
    }
    .ng2-smart-th  {
        color: white;
    }
    .ng2-smart-page-link.page-link {
        color: $primary;
    }
    .clickable-rows tr.ng2-smart-row:hover {
        cursor: pointer;
    }
    th a.ng2-smart-action {
      color: white !important;
    }
    td a.ng2-smart-action {
      color: $primary !important;
    }
    .ng2-smart-row.selected {
      background-color: $dark-10 !important;
    }
    &.table-hover tbody tr:hover {
      background-color: $dark-10;
    }
}
