@use 'sass:color';

$dark: #002037;
$primary: #79baa1;

$dark-90: color.mix($dark, white, 90%);
$dark-80: color.mix($dark, white, 80%);
$dark-70: color.mix($dark, white, 70%);
$dark-60: color.mix($dark, white, 60%);
$dark-50: color.mix($dark, white, 50%);
$dark-40: color.mix($dark, white, 40%);
$dark-30: color.mix($dark, white, 30%);
$dark-20: color.mix($dark, white, 20%);
$dark-10: color.mix($dark, white, 10%);

.bg-dark-10-pct {
  background-color: $dark-10;
}

$primary-90: color.mix($primary, white, 90%);
$primary-80: color.mix($primary, white, 80%);
$primary-70: color.mix($primary, white, 70%);
$primary-60: color.mix($primary, white, 60%);
$primary-50: color.mix($primary, white, 50%);
$primary-40: color.mix($primary, white, 40%);
$primary-30: color.mix($primary, white, 30%);
$primary-20: color.mix($primary, white, 20%);
$primary-10: color.mix($primary, white, 10%);