$menu-background-color: white;
$active-hover-link-background-color: $dark-10;

.bb-sidebar .list-group-item {
  border: none;
}
.sidebar{
    background-color: $menu-background-color;
    border-right: 1px solid $primary;
    .menu-button {
        background: $menu-background-color;
        border: none;
        position: absolute; right: 0;
        font-size: 24px;
        em {
            color: $light;
        }
    }
    .list-group{
        a.list-group-item {
            background: $menu-background-color;
            color: $dark;
            border: 0px;
            font-weight: 700;
        }
        a:hover {
            background: $active-hover-link-background-color;
            color: $dark;
        }
        a.router-link-active {
            background: $active-hover-link-background-color;
            color: $dark;
            border-left: 4px solid $primary;
        }
    }
    .sidebar-dropdown {
        .panel-title {
            a {
                color: $dark;
                background: $menu-background-color;
            }
            a:hover, a:focus {
                color: $light;
            }
        }
        .panel-title:hover {
            background: darken($menu-background-color, 5%);
        }
   /*     .panel-collapse {
            .panel-body {
                .list-group-item {
                    background-color: $menu-background-color;
                    a {
                        color: $text-color-dark;
                    }
                    a:hover {
                        color: $text-color-light;
                    }
                }
                .list-group-item:hover {
                    background: darken($menu-background-color, 5%);
                }
            }
        }*/
    }
}
.nested-menu {
    ul.submenu {
        background-color: $menu-background-color;
    }
    & .expand {
        ul.submenu {
            li {
                a {
                    color: $dark;
                    font-style: italic;
                }
                a.router-link-active {
                    background: $active-hover-link-background-color;
                    color: $dark;
                    border-left: 4px solid $primary;
                }
            }
        }
    }
}
.minimal-menu{
    background-color: $menu-background-color;
    .menu-button{
        background: $menu-background-color;
        position: absolute; right: 0; top:-100;
        font-size: 24px;
        border: none;
        em {
//             color: $light;
        }
    }
    .list-group{
        a.list-group-item {
            background: $menu-background-color;
            color: $dark;
        }
        a:hover {
            background: darken($menu-background-color, 5%);
        }
        a.router-link-active {
            background: $active-hover-link-background-color;
//             color: $light;
            border-left: 4px solid $primary;
        }
    }
    .sidebar-dropdown {
        .panel-title {
            a {
                color: $dark;
                background: $menu-background-color;
            }
            a:hover, a:focus {
                color: $light;
            }
        }
        .panel-title:hover {
            background: darken($menu-background-color, 5%);
        }
        .panel-collapse {
            .panel-body {
                .list-group-item {
                    background-color: $menu-background-color;
                    a {
                        color: $dark;
                    }
                    a:hover {
                        color: $light;
                    }
                }
                .list-group-item:hover {
                    background: darken($menu-background-color, 5%);
                }
            }
        }
    }
    .nested-menu {
        ul.submenu {
            background-color: $menu-background-color;
        }
        & .expand {
            ul.submenu {
                li {
                    a {
//                        color: $light;
                    }
                    a.router-link-active {
                        background: $active-hover-link-background-color;
//                        color: $light;
                        border-left: 4px solid $primary;
                    }
                }
            }
        }
    }
}
.push-right .sidebar {
  left: auto !important;
  right: 0 !important;
}

.minimal-menu{
    .list-group{
        a.router-link-active {
            border-left: 40px solid ;
        }
    }
}

@media screen and (max-width: 992px) {
  .push-right .sidebar {
    left: 235px !important;
  }
}
